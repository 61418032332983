import gsap from "gsap";

export function initializeCircleFollower(circle, proximityDistance, maxScale) {
  let mouseX = 0;
  let mouseY = 0;
  let circleX = 0;
  let circleY = 0;

  document.addEventListener("mousemove", (event) => {
    mouseX = event.clientX;
    mouseY = event.clientY;
  });

  function updateCirclePosition() {
    const dx = mouseX - circleX;
    const dy = mouseY - circleY;
    circleX += dx * 0.3;
    circleY += dy * 0.3;

    const circleWidth = circle.offsetWidth;
    const circleHeight = circle.offsetHeight;
    const circleCenterX = circleX - circleWidth / 2;
    const circleCenterY = circleY - circleHeight / 2;

    const allElements = document.querySelectorAll("a");
    const isCloseToAnyElement = Array.from(allElements).some((element) => {
      const rect = element.getBoundingClientRect();
      const elementX = rect.left + rect.width / 2;
      const elementY = rect.top + rect.height / 2;
      const distance = Math.hypot(
        elementX - circleCenterX,
        elementY - circleCenterY
      );
      return distance <= proximityDistance;
    });

    const targetScale = isCloseToAnyElement ? maxScale : 1;

    circle.classList.toggle("is-in-proximity", isCloseToAnyElement);

    if (mouseX === 0 && mouseY === 0 && circleX === 0 && circleY === 0) {
      mouseX = window.innerWidth / 2;
      mouseY = window.innerHeight / 2;
      circleX = window.innerWidth / 2;
      circleY = window.innerHeight / 2;

      gsap.set(circle, {
        x: window.innerWidth / 2,
        y: window.innerHeight / 2,
      });
    } else {
      gsap.to(circle, {
        duration: 0.2,
        scale: targetScale,
        x: circleCenterX,
        y: circleCenterY,
        ease: "power2.out",
      });
    }

    requestAnimationFrame(updateCirclePosition);
  }

  updateCirclePosition();
}
