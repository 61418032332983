export function initializeGlitch(container) {
  if (!container) return;

  const RAD = Math.PI / 180;
  const MAX_FRAMES = 1000;
  const MULTIPLIER = 10;
  const DISPLACEMENT_SCALE = 0;
  const baseFrequencyY = 0.01;

  const turbulence = document.getElementById("glitch-turbulence");
  const displacement = document.getElementById("glitch-displacement");
  const source = document.getElementById("logo-svg");

  let frames = 0;
  let turbulenceMultiplier = MULTIPLIER;
  let shakeAmount = 0;

  function render() {
    if (frames > MAX_FRAMES) turbulenceMultiplier = -MULTIPLIER;
    if (frames < 0) turbulenceMultiplier = MULTIPLIER;

    frames += turbulenceMultiplier;

    const baseFrequencyX = 0.01 + 0.005 * Math.cos(frames * RAD);

    turbulence.setAttribute(
      "baseFrequency",
      `${baseFrequencyX} ${baseFrequencyY}`
    );

    // Randomly glitch out if above a certain threshold
    if (Math.random() > 0.98) {
      const scale = Math.floor(Math.random() * 500) + 1;
      const sourceScale = Math.random() * 1.5 + 0.5;
      shakeAmount = 50; // Adjust the shake distance
      const offsetX = Math.random() * shakeAmount - shakeAmount / 2;
      const offsetY = offsetX / 2; // much less on the Y
      source.style.transform = `translate(${offsetX}px, ${offsetY}px) scaleX(${sourceScale})`;
      displacement.setAttribute("scale", `${scale}`);
    } else {
      shakeAmount = 0;
      source.style.transform = `translate(0) scale(1)`;
      displacement.setAttribute("scale", `${DISPLACEMENT_SCALE}`);
    }

    requestAnimationFrame(render);
  }

  render();
}
