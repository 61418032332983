export function initializeTextScramble(
  links,
  scrambleDuration,
  scrambleInterval
) {
  // Function to scramble the text
  function scrambleText(link) {
    const originalText = link.textContent;
    const chars = originalText.split("");
    const scrambledChars = chars.sort(() => Math.random() - 0.5);
    link.textContent = scrambledChars.join("");
  }

  // Function to restore the original text
  function restoreText(link) {
    const originalText = link.dataset.originalText;
    link.textContent = originalText;
  }

  // Add event listeners for each link
  links.forEach((link) => {
    link.dataset.originalText = link.textContent; // Store the original text in a data attribute

    let scrambleIntervalId = null; // Variable to store the interval ID

    link.addEventListener("mouseenter", () => {
      let elapsedTime = 0; // Variable to track the elapsed time during scrambling

      scrambleIntervalId = setInterval(() => {
        if (elapsedTime >= scrambleDuration) {
          clearInterval(scrambleIntervalId); // Stop the interval after the specified duration
          restoreText(link); // Restore the original text
        } else {
          scrambleText(link); // Scramble the text
          elapsedTime += scrambleInterval; // Increment the elapsed time
        }
      }, scrambleInterval);
    });

    link.addEventListener("mouseleave", () => {
      clearInterval(scrambleIntervalId); // Clear the interval if the mouse leaves before the duration
      restoreText(link); // Restore the original text
    });
  });
}
