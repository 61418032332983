import * as THREE from "three";

import vertex from "./cloud.vert";
import fragment from "./cloud.frag";

const MAX_WIDTH = 960;
const MAX_HEIGHT = 540;
class Clouds {
  constructor(container) {
    this.scene = new THREE.Scene();
    this.container = container;
    this.time = 0;
  }

  start() {
    this.renderer = new THREE.WebGLRenderer();
    this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    this.container.appendChild(this.renderer.domElement);

    window.addEventListener("resize", this.update.bind(this));

    this.camera = new THREE.Camera();
    this.camera.position.z = 1;

    this.addObjects();
    this.render();
    this.update();
  }

  update() {
    if (!this.renderer) return;

    const { innerWidth, innerHeight } = window;

    const width = Math.min(innerWidth, MAX_WIDTH);
    const height = Math.min(innerHeight, MAX_HEIGHT);

    this.renderer.setSize(innerWidth, innerHeight);
    this.uniforms.u_resolution.value.x = width;
    this.uniforms.u_resolution.value.y = height;
  }

  addObjects() {
    this.geometry = new THREE.PlaneGeometry(2, 2);

    this.uniforms = {
      u_time: { type: "f", value: 1000.0 },
      u_resolution: { type: "v2", value: new THREE.Vector2() },
    };

    this.material = new THREE.ShaderMaterial({
      extensions: {
        derivatives: "#extension GL_OES_standard_derivatives : enable",
      },
      side: THREE.FrontSide,
      uniforms: this.uniforms,
      vertexShader: vertex,
      fragmentShader: fragment,
    });

    this.mesh = new THREE.Mesh(this.geometry, this.material);

    this.scene.add(this.mesh);
  }

  render() {
    this.time += 0.02;
    this.material.uniforms.u_time.value = this.time;

    this.raf = requestAnimationFrame(this.render.bind(this));
    this.renderer.render(this.scene, this.camera);
  }
}

export default Clouds;
