import gsap from "gsap";

export function initializeIntroAnimation() {
  const timeline = gsap.timeline({
    ease: "power2.inOut",
  });

  if (window.GLOBAL_1788.isMobile) {
    gsap.to("#clouds", { autoAlpha: 0.5, duration: 3 });
  } else {
    gsap.to("#clouds", { autoAlpha: 0.2, duration: 5 });
  }

  timeline
    .to("#logo", { autoAlpha: 1, y: 0, duration: 0.6 })
    .to(
      "#links li",
      { autoAlpha: 1, y: 0, duration: 0.2, stagger: 0.125 },
      "-=0.3"
    )
    .to(
      "#socials li",
      { autoAlpha: 1, y: 0, duration: 0.2, stagger: 0.1 },
      "-=0.4"
    );

  if (!window.GLOBAL_1788.isMobile) {
    timeline.to(
      "#circle",
      { autoAlpha: 1, y: 0, display: "block", duration: 0.5 },
      "-=0.3"
    );
  }

  return timeline;
}
