import { domReady } from "./lib/utils";
import { initializeClouds } from "./lib/scenes";
import { initializeCircleFollower } from "./lib/circleFollower";
import { initializeTextScramble } from "./lib/textScramble";
import { initializeIntroAnimation } from "./lib/introAnimation";
import { initializeGlitch } from "./lib/glitch";
import { initializeAnalytics } from "./lib/analytics";

initializeAnalytics();

domReady().then(() => {
  const isDev = process.env.NODE_ENV === "development";
  const isMobile = navigator.userAgent.toLowerCase().match(/mobile/i) !== null;
  const isWebgl = !!window.WebGLRenderingContext;

  window.GLOBAL_1788 = {
    isMobile: isMobile,
    isWebgl: isWebgl,
    isCapable: !isMobile && isWebgl,
    isTesting: isDev,
  };

  initializeIntroAnimation();

  const glitchContainer = document.getElementById("glitch");
  initializeGlitch(glitchContainer);

  if (isWebgl) {
    const cloudsContainer = document.getElementById("clouds");
    initializeClouds(cloudsContainer);
  }

  if (!isMobile) {
    const circle = document.getElementById("circle");
    const proximityDistance = 40;
    const maxScale = 3;
    initializeCircleFollower(circle, proximityDistance, maxScale);
  }

  const scrambleLinks = document.querySelectorAll("#links a");
  const scrambleDuration = 250;
  const scrambleInterval = 25;
  initializeTextScramble(scrambleLinks, scrambleDuration, scrambleInterval);
});
