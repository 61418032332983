#ifdef GL_ES
precision mediump float;
#endif

uniform vec2 u_resolution;
uniform float u_time;

const int octaves = 2;
const float seed = 43758.5453123;
const float seed2 = 73156.8473192;

// Function to generate 2D random values
vec2 random2(vec2 st, float seed) {
  st = vec2(dot(st, vec2(127.1, 311.7)), dot(st, vec2(269.5, 183.3)));
  return -1.0 + 2.0 * fract(sin(st) * seed);
}

// Value Noise function by Inigo Quilez
float noise(vec2 st, float seed) {
  vec2 i = floor(st);
  vec2 f = fract(st);
  vec2 u = f * f * (3.0 - 2.0 * f);
  
  vec2 r0 = random2(i + vec2(0.0, 0.0), seed);
  vec2 r1 = random2(i + vec2(1.0, 0.0), seed);
  vec2 r2 = random2(i + vec2(0.0, 1.0), seed);
  vec2 r3 = random2(i + vec2(1.0, 1.0), seed);

  return mix(
    mix(dot(r0, f - vec2(0.0, 0.0)), dot(r1, f - vec2(1.0, 0.0)), u.x),
    mix(dot(r2, f - vec2(0.0, 1.0)), dot(r3, f - vec2(1.0, 1.0)), u.x),
    u.y
  );
}

// Fractal Brownian Motion function
float fbm1(in vec2 _st, float seed) {
  float v = 0.0;
  float a = 0.5;
  vec2 shift = vec2(100.0);
  mat2 rot = mat2(cos(0.5), sin(0.5), -sin(0.5), cos(0.50));

  for (int i = 0; i < octaves; ++i) {
    v += a * noise(_st, seed);
    _st = rot * _st * 2.0 + shift;
    a *= 0.75;
  }

  return v;
}

// Pattern generation function
float pattern(vec2 uv, float seed, float time, inout vec2 q, inout vec2 r) {
  q = vec2(
    fbm1(uv + vec2(0.0, 0.0), seed),
    fbm1(uv + vec2(5.2, 1.3), seed)
  );

  r = vec2(
    fbm1(uv + 2.0 * q + vec2(1.7 - time / 2.0, 9.2), seed),
    fbm1(uv + 2.0 * q + vec2(8.3 - time / 2.0, 2.8), seed)
  );

  vec2 s = vec2(
    fbm1(uv + 6.0 * r + vec2(21.7 - time / 2.0, 90.2), seed),
    fbm1(uv + 6.0 * r + vec2(80.3 - time / 2.0, 20.8), seed)
  );

  vec2 t = vec2(
    fbm1(uv + 1.0 * s + vec2(121.7 - time / 2.0, 190.2), seed),
    fbm1(uv + 1.0 * s + vec2(180.3 - time / 2.0, 120.8), seed)
  );

  return fbm1(uv + 5.0 * t, seed); // cloud pattern resolution
}

void main() {
  vec2 uv = (gl_FragCoord.xy - 0.5 * u_resolution.xy) / u_resolution.y;

  float time = u_time / 10.0;

  mat2 rot = mat2(cos(time / 10.0), sin(time / 10.0), -sin(time / 10.0), cos(time / 10.0));
  uv = rot * uv;

  uv *= sin(u_time / 20.0) + 3.0;
  uv.x -= time / 3.0;

  vec2 q = vec2(0.0, 0.0);
  vec2 r = vec2(0.0, 0.0);

  float noiseval = pattern(uv * 0.1, seed, time, q, r);  // noise color detail
  vec3 colour = vec3(1.0 + noiseval);
  colour += q.x + q.y;
  colour -= fract(noiseval * 2.0) / 100.0;
  colour = 1.0 - colour;

  gl_FragColor = vec4(colour, 1.0);
}
